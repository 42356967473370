
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import ImageView from '@/components/ImageView.vue'
import DriverSet from '@/components/DriverSet.vue'
import PageUtil from "@/utils/page_util";
import {IMsgRecordItem} from "@/models/logistics_model";
import {dateFormat} from "@/utils/common";

interface IState {
  list: IMsgRecordItem[],
  total: number;
  loading: boolean;
  searches: any
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 100,
  },

  {
    title: '内容',
    dataIndex: 'message',
    key: 'message',
  },
  {
    title: '接收对象',
    dataIndex: 'shopAdmin',
    key: 'user',
  },
  {
    title: '类型',
    dataIndex: 'type',
    key: 'type',
    width: 150
  },
  {
    title: '发送时间',
    dataIndex: 'send_time',
    key: 'send_time',
    width: 170
  },
  {
    title: '阅读时间',
    dataIndex: 'read_time',
    key: 'read_time',
    width: 170
  },
  {
    title: '发送状态',
    dataIndex: 'type',
    key: 'status',
    width: 150
  },
];

export default defineComponent({
  name: 'UserMessageList',
  components: {ImageView, DriverSet},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      searches: {
        page_no: PageUtil.pageNo,
        page_size: PageUtil.pageSize,
        keyword: '',
      },
    })


    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page_no,
      pageSize: state.searches.page_size,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.page_size = pages.pageSize
      state.searches.page_no = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiLogistics.getDriverMessageList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.list || []
      }
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    // 初始化search参数
    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      handleTableChange,
      onSearch,
      resetSearch,
      dateFormat,
    }
  }
})
